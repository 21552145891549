import React, { useState } from 'react';
import {
  Container, Col, Row,
} from 'reactstrap';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Headline from '../components/Headline/Headline';
import ContactForm from '../components/ContactForm/ContactForm';
import Section from '../components/Section/Section';

const Kontakt = () => (
  <Layout headerWhite>
    <SEO
      title="Kontakt | Dr. Kostka - integrative Medizin und Ästhetik"
      description="Dr. K. Kostka bietet integrative Medizin und Ästhetik mitten in Bochum. Moderne Praxis ✓ Top Ausstattung ✓ Vereinbaren Sie jetzt einen Termin ✓"
    />
    <Section>
      <Headline subtitle="Praxis für integrative Medizin und Ästhetik">
        Kommen Sie mit uns in Kontakt
      </Headline>
      <Container>
        <Row>
          <Col xs="12" md={{ size: 8, offset: 2 }}>

            <ContactForm />

          </Col>

        </Row>
      </Container>
    </Section>

  </Layout>
);

export default Kontakt;
